
import Flash from 'react-reveal/Flash';
import Fade from 'react-reveal/Fade';
import './style.css'

const Footer = () => {
    return (
        <>
            <footer className='footer'>
                <Fade>
                <div className="footer-container">
                    <di className="wrapper-footer">

                        <span>2023 SYSTEC TELECOM. CNPJ: 46.673.731/0001-07</span>
                        <span>Desenvolvido por : <span><a href='https://wictorhugo.vercel.app/' target="_blank">Wictor Hugo</a></span> </span>

                    </di>
                </div>
                </Fade>
            </footer>
        </>
    )

}
export default Footer